$button-height-large: 56px;

.button {
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 17px 27px;
  font-size: 16px;

  &_search {
    width: 180px;
    gap: 14px;
  }

  &__primary {
    color: var(--chat-input);
    background-color: var(--reverse-message-text);
  }

  &__outline-primary {
    color: var(--reverse-message-text);

  }

  &__cancel {
    color: var(--system-pure-white);
    background-color: var(--system-error-red);

    &:hover {
      background-color: #df402b;
    }

    &_disabled {
      color: var(--system-pure-white);
      background-color: var(--system-error-red);
      opacity: 0.3;
      cursor: auto;
    }
  }

  &__outline-cancel {
    color: var(--system-error-red);
    border: 1px solid var(--system-error-red);

    &:hover {
      //color: var(--system-pure-white);
      background-color: var(--surface-mango);
    }

    &_disabled {
      color: var(--system-error-red);
      border: 1px solid var(--system-error-red);
      opacity: 0.3;
      cursor: auto;
    }
  }

  &__disabled {
    color: var(--chat-input);
    background-color: var(--reverse-message-text);
    opacity: 0.6;
    cursor: auto;
  }

  &__outline-primary-disabled {
    color: var(--brand);
    border: 1px solid var(--brand);
    opacity: 0.3;
    cursor: auto;
  }
}
