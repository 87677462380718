body {
  --chat-input: #fff;
  --box-color: #fff;
  --text-dark: rgba(61, 66, 71, 0.5);
  --message-text: #3D4247;
  --message-time-text: rgba(61, 66, 71, 0.4);
  --reverse-message-text: #147DC1;
  --scrollbar-thumb: #3D4247;
  --scrollbar-track: #D8D9DA;
  --message-border: #ECECEC;
  --reverse-message-border: #CFE4F1;
}

.app-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.app-main {
  height: 100%;
  background-color: var(--box-color);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-wrapper {
  overflow: auto;
}

.chat-input {
  border: none;
  outline: none;
  height: 32px;
  flex: 1;
  font-size: 14px;
  margin-right: 4px;
  background-color: transparent;
  color: var(--message-text);
  resize: none;
  font-family: 'Roboto', sans-serif;
  line-height: normal;
  box-sizing: unset;
  
  &_white {
    color: #E9E9E9;
  }

  &::placeholder {
    color: var(--text-dark);
    font-size: 14px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 12px 24px;
    border-top: 1px solid #D8D9DA;
    background-color: var(--chat-input);
    
    &_compact {
      padding: 20px 24px 15px 35px;
    }
  }
  
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: rgba(61, 66, 71, 0.07);
    border-radius: 6px;
    padding: 10px 10px 0 10px;
  }
}

.input-wrapper {
  font-size: 14px;
  display: flex;
  flex: 1 1;
  overflow: hidden;
  justify-content: space-between;
}

.chat-send-btn {
  cursor: pointer;
  margin-bottom: 5px;
  line-height: normal;
  
  svg {
    vertical-align: unset;
  }
  
  &_name {
    margin-bottom: 0;
  }
  
  &_disabled {
    cursor: unset;
  }
}

.message-wrapper {
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
  
  &_merge-messages {
    padding-bottom: 0;
  }

  &.reverse {

    .message-box {
      color: var(--reverse-message-text);

      &:hover {
        .message-box-replay {
          display: none;
        }
      }
      
      &-text {
        border-color: var(--reverse-message-border);
        &:before {
          border-color: transparent transparent var(--reverse-message-border) transparent;
        }
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.message-box {
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  width: auto;
  color: var(--message-text);
  position: relative;
  white-space: break-spaces;
  
  &_merge-messages {
    padding-top: 0;
  }
  
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    
    &_merge-messages {
      margin-bottom: 0;
    }
  }

  &-author {
    font-weight: 600;
  }
  
  &-settings {
    cursor: pointer;
    margin-top: 20px;
    
    &_merge-messages {
      margin-top: 0;
    }
    
    &-container {
      z-index: 1;
      display: none;
      background: #FFFFFF;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 20px;
      position: absolute;
      flex-direction: column;
      right: 10px;
    }
    
    &-item {
      display: flex;
      gap: 7px;
      margin-bottom: 10px;
      cursor: pointer;
      
      a {
        display: flex;
        gap: 7px;
        text-decoration: none;
        color: var(--message-text);
        &:hover {
          color: var(--reverse-message-text);
        }
      }
      
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: var(--reverse-message-text);
      }
    }
  }
  
  &-main-container {
    display: flex;
    position: relative;
    width: 100%;
    gap: 10px;
    max-width: 65vw;
    
    @media (max-width: 767px) {
      max-width: 90vw;
    }
  }
  
  &-edit-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &-text {
    width: 100%;
    border: 1px solid var(--message-border);
    border-radius: 6px;
    padding: 17px 85px 17px 25px;
    margin-top: 20px;
    
    &__data {
      max-width: 50vw;
      overflow: hidden;
      text-overflow: ellipsis;
      
      @media (max-width: 767px) {
        max-width: 60vw;
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 30px;
      right: auto;
      top: -14px;
      bottom: auto;
      border: 18px solid;
      border-color: transparent transparent white transparent;
    }

    &:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 30px;
      right: auto;
      top: -15px;
      bottom: auto;
      border: 18px solid;
      border-color: transparent transparent var(--message-border) transparent;
    }
    
    &_hide-arrow {
      margin-top: 0;
      
      &:after, &:before {
        content: none;
      }
    }
  }

  &-time {
    color: var(--message-time-text);
    display: flex;
    justify-content: flex-end;
    margin-top: 9px;
    position: absolute;
    right: 55px;
    top: 22px;
    
    &_merge-messages {
      top: 2px;
    }
  }

  &-replay {
    color: black;
    display: none;
    text-decoration: underline;
    cursor: pointer;
  }

  &-reply-close {
    cursor: pointer;
    margin-left: 10px;
  }

  &:hover {
    .message-box-replay {
      display: block;
    }
  }

  &-wrapper {
    width: 100%;
    margin: 0 12px;
    
    &_compact {
      margin: 0 26px 0 26px;
    }
    
    &-reply {
      flex-direction: row;
      display: flex;
      width: 100%;
      
      .message-box {
        border-left: 2px solid #8B8E91;
        border-radius: 0;
        width: 100%;
        margin-left: 10px;
        
        &-text {
          max-width: 300px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      
      &_message {
        margin-left: 0;
        margin-bottom: 15px;
        
        .message-box {
          margin-left: 0;
        }
      }
    }
    
    &-edit {
      border: 1px solid var(--message-border);
      padding: 18px;
      border-radius: 6px;
      margin: 0;
      
      .message-box {
        padding: 0 10px;
        max-width: 60vw;
        
        &-author {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.delete-message {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  flex-direction: column;
  right: 10px;
  z-index: 1;
  
  &__title {
    color: var(--message-text);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 22px;
  }
  
  &-btns-container {
    display: flex;
    align-items: center;
    gap: 19px;
  }
}

.delete-btn {
  cursor: pointer;
  background: #147DC1;
  border-radius: 8px;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 17px 27px 14px 26px;
}

.cancel-btn {
  cursor: pointer;
  color: #147DC1;
  font-size: 16px;
}

.chat-date {
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  color: #D8D9DA;
}

.add-file-btn {
  margin-right: 13px;
  margin-bottom: 8px;
  line-height: normal;
  
  svg {
    vertical-align: unset;
  }
  
  input {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.attachments-container {
  display: flex;
  flex-direction: column;
}

.attachment-item {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ECECEC;
  
  &:last-child {
    border-bottom: none;
  }
  
  &__text {
    display: flex;
    gap: 9px;
    font-weight: 500;
    font-size: 14px;
    color: #D0727A;
    align-items: center;
  }
  
  &__delete-btn {
    cursor: pointer;
  }
}

.message-text-margin {
  margin-bottom: 18px;
}

.message-attachment {
  display: flex;
  gap: 9px;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 14px;
  color: #D0727A;
  align-items: center;
  cursor: pointer;
  max-width: 500px;
  word-break: break-all;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  img {
    max-width: 100%;
  }
}

.message-read-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  
  &_merge-messages {
    top: 10px;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
}

.chat-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.chat-main {
  height: 100%;
  background-color: var(--box-color);
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media (max-width: 767px) {
    display: none;
  }
  
  &_show {
    @media (max-width: 767px) {
      display: flex;
    }
  }
}

.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 24px 35px 22px 28px;
  border-bottom: 1px solid #D8D9DA;
  max-height: 68px;
  
  &_compact {
    padding: 24px 35px 22px 37px;
  }
}

.chat-name {
  display: flex;
  gap: 9px;
  font-weight: 600;
  font-size: 18px;
  color: #147DC1;
  align-items: center;
}

.user-item {
  font-weight: 500;
  font-size: 14px;
  color: #3D4247;
  margin-left: 36px;
  margin-bottom: 22px;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
}

.navigation-btn {
  margin-left: 19px;
  font-size: 14px;
  color: rgba(61, 66, 71, 0.5);
}

.back-to-chat-container {
  display: flex;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
  padding: 10px 0;
  width: fit-content;
}

.search-input-container {
  display: flex;
  background: rgba(61, 66, 71, 0.07);
  border-radius: 6px;
  align-items: center;
  padding: 5px 10px;
  margin: 0 35px 15px 35px;
}

.search-message-item {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  margin: 0 35px;
  border-bottom: 1px solid #ECECEC;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &__name {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #3D4247;
  }

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #3D4247;
  }

  &__date {
    font-size: 10px;
    text-align: right;
    color: rgba(61, 66, 71, 0.4);
    margin-bottom: 14px;
  }

  &__time {
    font-size: 10px;
    text-align: right;
    color: rgba(61, 66, 71, 0.4);
  }
}

.chat-settings {
  cursor: pointer;
}

.chat-list {
  display: flex;
  flex-direction: column;
  background: var(--box-color);
  width: 30%;
  border-right: 1px solid #D8D9DA;
  
  @media (max-width: 767px) {
    display: none;
    width: 100%;
  }
  
  &_show {
    @media (max-width: 767px) {
      display: flex;
    }
  }
}

.unread-messages-count {
  position: absolute;
  right: 13px;
  bottom: 13px;
  border-radius: 50%;
  color: #fff;
  background: #147DC1;
  font-size: 10px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  &_selected {
    color: #147DC1;
    background: #fff;
  }
}

.chat-item {
  display: flex;
  flex-direction: column;
  color: #3D4247;
  font-size: 14px;
  padding: 12px 25px 13px 22px;
  border-bottom: 1px solid #D8D9DA;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #147DC1;
    color: #FFFFFF;

    .unread-messages-count {
      color: #147DC1;
      background: #fff;
    }
  }

  &_selected {
    background: #147DC1;
    color: #FFFFFF;
  }

  &__name {
    font-weight: 700;
  }

  &__user-name {
    font-weight: 500;
  }

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.chat-search-wrapper {
  display: flex;
  align-items: center;
  min-height: 68px;
  border-bottom: 1px solid #D8D9DA;
  padding-left: 24px;
  gap: 10px;

  input::placeholder {
    color: #3D4247;
  }
}

.chats-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 80%;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 767px) {
    height: 100%;
  }
}

.chat-back-to-list {
  display: none;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  width: fit-content;
  padding: 24px 0 0 28px;
  
  span {
    font-size: 18px;
    margin-right: 10px;
    width: 10px;
  }

  @media (max-width: 767px) {
    display: flex;
  }
}
