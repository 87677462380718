.player-btns-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 50px
}

.player-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.player-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.player-wrapper {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  color: #404040;
  background: #4A4A4A;
}