.tm-communication-spin {
  &-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &-circle {
    :after {
      border-radius: 50%;
      width: 5em;
      height: 5em;
    }
    border-radius: 50%;
    width: 5em;
    height: 5em;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.35em solid rgba(220, 220, 220, 0.5);
    border-right: 0.35em solid rgba(220, 220, 220, 0.5);
    border-bottom: 0.35em solid rgba(220, 220, 220, 0.5);
    border-left: 0.35em solid #147DC1;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;

    &_white {
      border-left: 0.35em solid #fff;
    }

    &_invert {
      border-left: 0.35em solid #fff;
    }
    
    &_small {
      width: 1.5em;
      height: 1.5em;
      :after {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}