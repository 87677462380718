.modal-back {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.toasts-back {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.modal-content {
  background: #ffffff;
  overflow: hidden;
  height: 100%;
  border-radius: 4px;
}

.modal-header {
  background: #ededed;
  border-bottom: 1px solid #ccc;
}

.modal-footer {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
}

.modal-title {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  &__close {
    cursor: pointer;
  }

  &_right {
    display: flex;
    justify-content: flex-end;
  }
}

.modal-body {
  padding: 0 32px 32px 32px;
  height: calc(100% - 100px);
}

.dialog-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(8, 11, 48, 0.2);

  &_shade {
    pointer-events: none;
    filter: grayscale(75%) brightness(75%);
    // height: inherit;
  }
}

.modal-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: var(--text-primary);
}

.modal-sub-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-primary);
}

.modal-item {
  display: flex;
  gap: 8px;

  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: var(--text-primary);
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: var(--text-secondary);
  }
}

.modal-text-container {
  display: flex;
  gap: 24px;
}

.toast-title {
  padding: 16px 16px 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    font-weight: 400;
    font-size: 20px;
  }

  &__close {
    cursor: pointer;
  }

}

.toast-body {
  padding: 0 16px 16px 44px;
  height: calc(100% - 100px);
  font-size: 16px;
}

.toast-content {
  background: var(--system-pure-white);
  overflow: hidden;
  height: 100%;
  box-shadow: 0px 4px 16px rgba(8, 11, 48, 0.2);
}
