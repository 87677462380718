:root {
  --input-border: #e3e3e3;
  --input-border-error: #fa0000;
  --input-label-color: #798491;
  --input-label-error: #fa0000;
}

.form-input {
  padding: 9px;
  border: 1px solid var(--input-border);
  border-radius: 8px;
  font-size: 16px;
  outline: none;

  &_error {
    border: 1px solid var(--input-border-error);
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--input-label-color);

    &_error {
      color: var(--input-label-error);
    }
  }

  &__required {
    display: inline-block;
    color: var(--input-label-error);
  }

  &__description {
    font-size: 12px;
    color: var(--input-label-color);
  }
}