.form-select {
  &__container {
    display: flex;
    flex-direction: column;

    margin-bottom: 16px;
  }
  &__title {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--input-label-color);

    &_error {
      color: var(--input-label-error);
    }
  }

  &__required {
    display: inline-block;
    color: var(--input-label-error);
  }

  &__description {
    font-size: 12px;
    color: var(--input-label-color);
  }
}