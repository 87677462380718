.chat-container {
    height: 100%;
    width: 100%;
    color: #404040;
    background: #4A4A4A;
    position: relative;
}

.chat-wrapper {
    padding-top: 10px;
    width: 100%;
    height: 100%;
}

.room-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.video-main-container {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
}

.messenger-container {
    min-width: 30%;
    max-width: 30%;
    background: #fff;
    height: 100%;

    @media (max-width: 767px) {
        position: absolute;
        right: 0;
        min-width: 80%;
        z-index: 3;
        height: 80%;
    }
    
    &_hidden {
        visibility: hidden;
        position: absolute;
        z-index: -1;
    }
}

.messenger-wrapper {
    width: 100%;
    height: calc(100% - 40px);
}

.records-wrapper {
    height: calc(100% - 40px);
    padding: 30px 38px;
}

.records-title {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
    color: #3D4247;
}

.records-item {
    font-weight: 500;
    font-size: 14px;
    color: #147DC1;
    margin-bottom: 24px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    
    &__diff {
        font-weight: 400;
        font-size: 12px;
        color: rgba(61, 66, 71, 0.4);
    }
    
    &_compact {
        margin-left: 37px;
        margin-right: 35px;
    }
    
    &_disabled {
        cursor: default;
    }
}

.messenger-header {
    margin: 10px;
}

.close-btn {
    width: fit-content;
    cursor: pointer;
}

.recording-container {
    position: absolute;
    display: flex;
    gap: 8px;
    bottom: 50px;
    left: 30px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    align-items: center;
}

.buttons-container {
    z-index: 2;
    position: absolute;
    bottom: 80px;
    display: flex;
    gap: 30px;
    width: 100%;
    justify-content: center;
    
    &_main-settings {
        position: unset;
        padding-top: 5px;
        padding-bottom: 20px;
    }
    
    &_call-settings {
        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
    }
}

.enter-button {
    width: 520px;
    display: flex;
    justify-content: center;
    color: #fff;
    background: #147DC1;
    gap: 10px;
    padding: 10px 0;
    border-radius: 8px;
    cursor: pointer;
    
    &_disabled {
        background: #ccc;
    }
}

.main-chat-btns-container {
    display: flex;
    gap: 3px;

    @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
    }

    .chat-btn:first-child {
        border-radius: 8px 0 0 8px;
    }

    .chat-btn:last-child {
        border-radius: 0 8px 8px 0;
    }
}

.chat-btn {
    padding: 9px 19px;
    background: #147DC1;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    
    &__leave {
        background: #C13314;
        padding: 4px 16px;
        border-radius: 8px;
    }
    
    &__settings {
        border-radius: 8px;
        position: relative;
    }
    
    &_off {
        background: #323232;
    }

    &_disabled {
        background: grey;
    }
    
    &_share {
        @media (max-width: 767px) {
            display: none;
        }
    }
    
    &_white-board {
        @media (max-width: 767px) {
            display: none;
        }
    }
    
    &_messages-count {
        border-radius: 50%;
        background: #C13314;
        color: #fff;
        display: flex;
        min-width: 20px;
        min-height: 20px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        top: 5px;
        right: 10px;
    }
}

.chat-settings-container {
    position: absolute;
    display: flex;
    background: #fff;
    border-radius: 6px;
    color: #4A4A4A;
    font-weight: 500;
    font-size: 14px;
    flex-direction: column;
    width: fit-content;
    white-space: nowrap;
    bottom: 55px;
    left: 0;
    padding: 9px 0;
    div {
        padding: 9px 24px;
        
        &:hover {
            background: #147DC1;
            color: #fff;
        }
    }
}

.participant {
    min-width: 33%;
    max-width: 33%;
    height: 33%;
    background: #242424;
    overflow: hidden;
    position: relative;
    margin-bottom: 7px;
    
    &__mic {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(36, 36, 36, 0.8);
    }
    
    &__name {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        background: rgba(36, 36, 36, 0.8);
        text-align: center;
        padding: 5px 10px;
        position: absolute;
        width: 100%;
        bottom: 0;
    }
    
    &_screen {
        min-width: 90% !important;
        max-width: 90% !important;
        height: 90% !important;
        background: transparent;
        
        video {
            object-fit: contain !important;
        }
    }
    
    &_hidden {
        display: none;
    }
}

.participant video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.participants-container {
    width: 100%;
    display: flex;
    gap: 4px;
    height: calc(100% - 150px);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
    &_sharing-screen {
        margin-top: 120px;
        height: calc(100% - 250px);

        @media (max-width: 767px) {
            position: absolute;
            height: calc(100% - 250px) !important;
            width: calc(100% - 40px);
            left: 20px;
        }
    }
    
    &:has(> :last-child:nth-child(1)) {
        .participant {
            height: 60%;
            min-width: 40%;
            max-width: 40%;
        }
    }

    &:has(> :last-child:nth-child(2)) {
        .participant {
            height: 60%;
            min-width: 40%;
            max-width: 40%;
        }
    }

    &:has(> :last-child:nth-child(3)) {
        .participant {
            height: 50%;
            min-width: 33%;
            max-width: 33%;
        }
    }

    &:has(> :last-child:nth-child(4)), &:has(> :last-child:nth-child(5)), &:has(> :last-child:nth-child(6)) {
        .participant {
            height: 40%;
            min-width: 33%;
            max-width: 33%;
        }
    }

    @media (max-width: 767px) {
        height: 100px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: unset;
        overflow-y: auto;
        gap: 10px;
        
        .participant {
            min-width: 100px;
            min-height: 100px;
        }
        &:has(> :last-child:nth-child(1)), &:has(> :last-child:nth-child(2)) {
            .participant {
                max-width: 100px;
                min-width: 100px;
                height: 200px;
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 2;

                &.main {
                    right: 0;
                    top: 0;
                    height: 100% !important;
                    z-index: 1;
                }
            }
        }

        &:has(> :last-child:nth-child(2)) {
            .participant {
                &.main {
                    right: 0;
                    top: 30%;
                    height: 50% !important;
                    z-index: 1;
                }
            }
        }
    }

    &_sharing {
        position: absolute;
        top: 0;
        align-items: flex-start;

        .participant {
            height: 100px !important;
            min-width: 200px !important;
            max-width: 200px !important;
        }

        @media (max-width: 767px) {
            .participant {
                height: 50px !important;
                min-width: 100px !important;
                max-width: 100px !important;
            }
            .participant.main {
                height: 50px !important;
                min-width: 100px !important;
                max-width: 100px !important;
                position: relative !important;
                top: unset;
            }
        }
    }
    
    &_hidden {
        visibility: hidden;
        position: absolute;
        z-index: -1;
    }
}

.participant {
    &.main {
        @media (max-width: 767px) {
            position: absolute !important;
            width: 100%;
            min-width: 100% !important;
            top: 140px;
            height: calc(100% - 150px) !important;
        }
    }
}

.block-btns-container {
    display: flex;
    gap: 20px;
    height: fit-content;
    position: absolute;
    bottom: 50px;
    right: 20px;
    z-index: 2;
}

.block-btn {
    cursor: pointer;
    height: fit-content;    
    
    &_disabled {
        cursor: default;
    }
}
.join-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.join-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #808080;
    border-radius: 8px;
    padding: 80px 130px;
    max-width: 300px;
    cursor: pointer;
    &__text {
        font-size: 16px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
}

.register-text {
    font-weight: 700;
    font-size: 45px;
    max-width: 600px;
    text-align: center;
    color: #FFFFFF;

}

.register-btn {
    background: #147DC1;
    border-radius: 8px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: #FFFFFF;
    padding: 17px 26px;
    margin-top: 30px;
}
.invite-modal {
    background: #fff;
    border-radius: 5px;
    padding: 30px;
}
.invite-modal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(61, 66, 71, 0.2);
}
.user-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.user-input-hint {
    font-size: 14px;
    color: #E9E9E9;
    min-width: 520px;
    @media (max-width: 767px) {
        font-size: 11px;
        min-width: 70%;
    }
}

.user-input-wrapper {
    display: flex;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 6px;
    min-width: 500px;
    margin-top: 11px;
    padding: 5px 10px;
    align-items: center;
    
    @media (max-width: 767px) {
        min-width: 80%;
    }
}

.message-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &__text {
        color: white;
        font-size: 25px;
        padding-top: 50px;
        text-align: center;
    }
}

.choose-camera-container {
    margin-top: 10px;
    z-index: 3;
    
    @media (max-width: 767px) {
        min-width: calc(100% - 20px);
    }
}

.main-input-camer {
    width: 50%;
    height: 60%;
    object-fit: cover;
    background: #000;
    @media (max-width: 767px) {
        height: 40%;
    }
}

.timeout-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    
    @media (max-width: 767px) {
        max-width: 360px;
    }
    
    &__text {
        font-size: 18px;
        text-align: center;
        color: white;
    }
}